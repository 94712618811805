/**
* Created by ZJ on 2021/12/9 10:45.
*/
<template>
  <div class="base-content">
    <query-frame
      :current="pageNo"
      :total="total"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
        <query-item label="生效日期">
          <a-date-picker
            @change="onChangestart"
            format="YYYY-MM-DD"
          ></a-date-picker>
        </query-item>
        <query-item label="失效日期">
          <a-date-picker
            @change="onChangeend"
            format="YYYY-MM-DD"
          ></a-date-picker>
        </query-item>
      </template>
      <!-- 查询条件结束 -->
      <template slot="btns">
        <a-button type="primary" icon="download" @click="handleDownLoad"
          >全员下发</a-button
        >
        <a-button
          type="primary"
          icon="profile"
          @click="goRecord"
          style="margin-left: 10px"
          >下发记录</a-button
        >
      </template>
      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table
          :columns="columns"
          :dataSource="dataList"
          @selectRowChange="selectCouponChange"
        >
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
    <a-modal
      v-model="userCouponVisible"
      width="800px"
      height="600px"
      :maskClosable="false"
      @ok="handleSendUser"
    >
      <div>
        <!-- <div :style="{ borderBottom: '1px solid #E9E9E9' }">
          <a-checkbox
            :indeterminate="indeterminate"
            v-model:checked="isChecked"
            @change="onCheckAllUserCouponChange"
          >
            全部成员
          </a-checkbox>
        </div> -->

        <div>
          <a-form-model layout="inline" :disabled="isChecked">
            <a-form-model-item label="手机">
              <a-input v-model="MemberIphone" placeholder="MemberIphone">
                <a-icon slot="prefix" type="phone" />
              </a-input>
            </a-form-model-item>

            <a-form-model-item label="优惠劵数量">
              <a-input-number id="inputNumber" v-model="couponCnt" :min="1" :max="100" />
            </a-form-model-item>
            <a-form-model-item>
              <a-button type="primary" @click="FindMemberList()">
                查询
              </a-button>
            </a-form-model-item>
            <a-form-model-item>
              <a-button type="primary" html-type="reset"> 重置 </a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
        <br />
        <div style="width: 100%">
          <i-table
            ref="membertable"
            :columns="Membercolumns"
            :dataSource="MemberdataList"
            @selectRowChange="selectRowChange"
          >
          </i-table>
          <!-- <j-select-multiple
            :readOnly="isChecked"
            style="width: 100%"
            v-model="currentCheckedList"
            :options="userPlainOptions"
          /> -->
        </div>
      </div>
    </a-modal>
    <add-coupon
      :visible="addCouponVisible"
      ref="addcoupon"
      :form="currentNode"
      @ok="handleOk"
      @cancel="handleCancel"
    ></add-coupon>
    <a-modal
      v-model="userAllCouponVisible"
      width="500px"
      height="600px"
      ref="couponmodal"
      :maskClosable="false"
      @ok="handleAllSendUser"
    >
      <br />
      <div>
        <a-table
          size="small"
          bordered
          :rowKey="'value'"
          :pagination="false"
          :scroll="{ y: 150 }"
          :columns="couponsColumns"
          :data-source="couponDatasoure"
        ></a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment';
import {
  couponList,
  editCoupon,
  deleteCoupon,
  getCouponMember,
  addUserCoupon,
} from '@/api/coupon';
import queryMixin from '@/mixin/queryMixin';
import { isEmpty, deepClone } from '@/libs/utils';
import addCoupon from './addCoupon';
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil';
import { loadCategoryCarData } from '@/api/manage';
import JSelectMultiple from '@/components/JSelectMultiple';
import { memberList } from '@/api/member';

export default {
  name: 'CouponList',
  mixins: [queryMixin],
  components: {
    addCoupon,
    JSelectMultiple,
  },
  props: {},

  data() {
    return {
      addCouponVisible: false,
      userAllCouponVisible: false,
      carDictOptions: {},
      couponDatasoure: [],
      couponsColumns: [
        {
          title: '优惠券名称',
          dataIndex: 'label',
          key: 'label',
          ellipsis: true,
          width: 150,
        },
        {
          title: '数量',
          dataIndex: 'cnt',
          key: 'cnt',
          ellipsis: true,
          width: 100,
          customRender: (text, row, index) => {
            return (
              <section>
                <a-input-number
                  {...{
                    attrs: {
                      min: 1,
                      step: 1,
                      max: 100,
                      value: text,
                    },
                  }}
                  vOn:change={(val) => this.handleChange(val, row)}
                  style="width: 100%;"
                ></a-input-number>
              </section>
            );
          },
        },
      ],
      // 默认展示表格
      Membercolumns: [
        {
          title: '门店名称',
          dataIndex: 'shopName',
          key: 'shopName',
          ellipsis: true,
          width: 100,
        },
        {
          title: '姓名',
          dataIndex: 'realName',
          key: 'realName',
          width: 80,
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          key: 'mobile',
          ellipsis: true,
          width: 80,
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          width: 80,
          customRender: (text, row, index) => {
            return (
              <a-switch
                checked-children="启用"
                un-checked-children="禁用"
                checked={row.status === 0}
              />
            );
          },
        },
      ],
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          },
        },
        {
          title: '优惠券名称',
          dataIndex: 'name',
          key: 'name',
          width: 200,
          ellipsis: true,
        },
        {
          title: '优惠券类型',
          dataIndex: 'types',
          key: 'types',
          ellipsis: true,
          width: 120,
          customRender: (text, record, index) => {
            const typeDict = ['体验券', '优惠券', '转赠券', '礼包', '充值券'];
            return typeDict[+(text - 1)];
          },
        },
        {
          title: '张数',
          dataIndex: 'cnt',
          key: 'cnt',
          ellipsis: true,
          width: 80,
        },
        {
          title: '金额(RM)',
          dataIndex: 'cash',
          key: 'cash',
          ellipsis: true,
          width: 80,
        },
        {
          title: '使用条件(RM)',
          dataIndex: 'conditioncash',
          key: 'conditioncash',
          ellipsis: true,
          width: 120,
        },
        {
          title: '使用范围',
          dataIndex: 'userange',
          key: 'userange',
          ellipsis: true,
          width: 200,
          customRender: (text) => (text
            ? filterMultiDictText(this.carDictOptions.carCategoryId, text)
            : ''),
        },
        {
          title: '有效期',
          dataIndex: 'indate',
          key: 'indate',
          ellipsis: true,
          width: 200,
          customRender: (text, record, index) => {
            let temp = '';
            if (record.endflag === 1) {
              if (record.endTime === '9999-12-30') {
                temp = '长期有效';
              } else {
                temp = `${record.startTime}至${record.endTime}`;
              }
            } else if (record.endflag == 0) {
              temp = `${record.days}(天)`;
            } else if (record.endflag == 2) {
              temp = '长期有效';
            }
            return temp;
          },
        },
        {
          title: '创建人',
          dataIndex: 'createBy',
          key: 'createBy',
          ellipsis: true,
          width: 120,
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          ellipsis: true,
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 260,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return (
              <section>
                <a-button
                  type="link"
                  size="small"
                  onClick={() => this.editCouon(row)}
                >
                  编辑
                </a-button>
                <a-divider type="vertical"></a-divider>
                <a-button
                  type="link"
                  size="small"
                  onClick={() => this.addUserCouponItem(row)}
                >
                  下发
                </a-button>
                <a-divider type="vertical"></a-divider>
                <a-button
                  type="link"
                  size="small"
                  onClick={() => this.deleteItem(row)}
                >
                  删除
                </a-button>
              </section>
            );
          },
        },
      ],
      dataList: [],
      MemberdataList: [],
      MemberIphone: '',
      currentNode: {},
      isEditCoupon: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      couponCnt: 1,
      userCouponVisible: false,
      currentCheckedList: '',
      userPlainOptions: [],
      isChecked: false,
      checkAllUserPlain: false,
      indeterminate: false,
      startTime: '',
      endTime: '',
      selectCouponKeyList: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.handleQuery();
    this.initDictConfig();
    this.getMemberData();
    // console.log(moment().subtract(0, 'days').format('YYYY-MM-DD 23:59:59'))
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleChange(val, row) {
      row.cnt = val;
    },
    selectCouponChange(data) {
      console.log(data.toString());
      this.selectCouponKeyList = data.toString();
    },
    selectRowChange(data) {
      this.currentCheckedList = data.toString();
      console.log(`selectRowChange:${data}`);
    },
    handleDownLoad() {
      if (this.selectCouponKeyList.length <= 0) {
        this.$message.warning('请选择要下发的优惠券');
        return;
      }
      this.couponDatasoure = [];
      console.log(this.selectCouponKeyList);
      const arr = this.selectCouponKeyList.split(',');
      const TmpArr = [];
      for (let i = 0; i < arr.length; i++) {
        for (let k = 0; k < this.dataList.length; k++) {
          if (this.dataList[k].id === arr[i]) {
            const item = {};
            item.label = this.dataList[k].name;
            item.value = this.dataList[k].id;
            item.cnt = 1;
            TmpArr.push(item);
            break;
          }
        }
      }
      this.couponDatasoure = JSON.parse(JSON.stringify(TmpArr));
      this.userAllCouponVisible = true;
    },
    FindMemberList() {
      this.getMemberData();
    },
    // 获取会员列表
    getMemberData() {
      const paramss = {};
      console.log('------------------');
      if (this.MemberIphone !== '') {
        paramss.mobile = this.MemberIphone;
        // paramss.phone = this.MemberIphone;
      }
      this.MemberdataList = [];
      memberList(paramss).then((data) => {
        const { totalCount, list } = data;
        list.forEach((item) => {
          item.key = item.memberId;
          item.id = item.memberId;
        });
        this.MemberdataList = list;
      });
    },
    // 获取列表
    getData() {
      const data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        endTime: this.endTime,
        startTime: this.startTime,
      };
      couponList(data).then((res) => {
        const { total, records } = res;
        this.dataList = records;
        this.total = total;
      });
    },
    onChangeend(date, dateString) {
      this.endTime = dateString;
      console.log('end', dateString);
    },
    onChangestart(date, dateString) {
      this.startTime = dateString;
      console.log('start', dateString);
    },
    handlePageChange(page) {
      this.pageNo = page;
      this.getData();
    },
    handleSizeChange(current, pageSize) {
      this.pageNo = 1;
      this.pageSize = pageSize;
      this.getData();
    },
    // 添加
    handleAdd() {
      this.$refs.addcoupon.loadValidCouponList();
      this.$refs.addcoupon.clearForm();
      this.isEditCoupon = false;
      this.currentNode = null;
      this.addCouponVisible = true;
    },

    handleQuery() {
      this.getData();
    },

    handleReset() {
      this.endTime = '';
      this.startTime = '';
      this.pageNo = 1;
    },

    goRecord() {
      this.$router.push({
        name: 'recordList',
      });
    },
    setParam(param) {
      const _param = {};
      for (const i in param) {
        if (i == 'indate') {
          _param.startTime = param[i].length > 0 ? param[i][0] : '';
          _param.endTime = param[i].length === 2 ? param[i][1] : '';
        } else if (i === 'types' && param[i] === 4) {
          _param.types = param.types;
          _param.lbids = param.lbids;
        } else if (i === 'lbids') {
          continue;
        } else if (i === 'endflag') {
          _param[i] = param[i];
        } else {
          _param[i] = typeof param[i] === 'boolean' ? +param[i] : param[i];
        }
      }
      return _param;
    },
    handleOk(param) {
      const _param = this.setParam(param);
      const that = this;
      this.addCouponVisible = false;
      editCoupon(_param)
        .then((res) => {
          that.$message.success(res);
          that.getData();
        })
        .catch((error) => {
          that.$message.warning(error);
        });
    },
    setCurrentNode(row) {
      this.currentNode = {};
      console.log('row++++++++++++++++++++', row);
      for (const i in row) {
        if (i == 'startTime' || i == 'endTime') {
          continue;
        } else if (i == 'holidayFlag' || i == 'dayFlag') {
          this.currentNode[i] = Boolean(row[i]);
        } else if (i === 'endflag') {
          if (row[i] === 1 && row.endTime === '9999-12-30') {
            this.currentNode[i] = 2;
          } else {
            this.currentNode[i] = row[i];
          }
        } else {
          this.currentNode[i] = row[i];
        }
      }
      this.currentNode.indate = [row.startTime, row.endTime];
      console.log('this.currentNode这里啥情况？', this.currentNode);
    },
    editCouon(row) {
      this.isEditCoupon = true;
      this.setCurrentNode(row);
      this.addCouponVisible = true;
    },
    handleCancel() {
      this.addCouponVisible = false;
    },
    deleteItem(row) {
      const that = this;
      this.$confirm({
        title: '确认删除',
        content: '是否删除选中优惠券?',
        onOk() {
          deleteCoupon(row.id)
            .then((res) => {
              that.$message.success(res);
              that.getData();
            })
            .catch((error) => {
              that.$message.warning(error);
            })
            .finally(() => {});
        },
      });
    },
    LoadMemberList() {
      const that = this;
      that.userPlainOptions = [];
      getCouponMember().then((res) => {
        res.forEach((item) => {
          that.userPlainOptions.push({
            label: item.realName,
            value: item.memberId,
          });
        });
        console.log(`userPlainOptions size:${that.userPlainOptions.length}`);
      });
    },
    addUserCouponItem(row) {
      this.setCurrentNode(row);
      this.userCouponVisible = true;
      this.currentCheckedList = [];
      // this.$refs.membertable.resetSelect();
      this.MemberIphone = '';
    },
    handleAllSendUser() {
      const that = this;
      for (let i = 0; i < this.couponDatasoure.length; i++) {
        const couponid = this.couponDatasoure[i].value;
        const param = new FormData();
        param.append('userids', '');
        param.append('allflag', 1);
        param.append('cnt', this.couponDatasoure[i].cnt);
        param.append('couponid', couponid);
        const that = this;
        addUserCoupon(param).then((res) => {
          that.getData();
          that.userAllCouponVisible = false;
        });
      }
    },
    handleSendUser() {
      const couponid = this.currentNode.id;
      const param = new FormData();
      param.append('userids', this.currentCheckedList);
      param.append('allflag', 0);
      param.append('cnt', this.couponCnt);
      param.append('couponid', couponid);
      const that = this;
      addUserCoupon(param).then((res) => {
        // that.$message.success(res);
        that.userCouponVisible = false;
        this.getData();
      });
    },
    onChangeUserCoupon() {
      this.indeterminate = !!this.currentCheckedList.length
        && this.currentCheckedList.length < this.userPlainOptions.length;
      this.checkAllUserPlain = this.currentCheckedList.length === this.userPlainOptions.length;
    },
    onCheckAllUserCouponChange(e) {
      console.log(`onCheckAllUserCouponChange${this.isChecked}`);
    },
    initDictConfig() {
      loadCategoryCarData({ code: '0' }).then((res) => {
        // console.log(res)
        if (res !== undefined) {
          this.$set(this.carDictOptions, 'carCategoryId', res);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
